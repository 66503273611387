@import '../variables'
  
.main
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 100vh

  &__comingText
    @extend %comingText
    color: $mainColor
    margin: 36px 60px