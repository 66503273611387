@import '../../styles/variables'

.form
  display: flex
  flex-direction: column
  align-items: center

  &__small
    @extend %secondText
    color: $mainColor
    margin-bottom: 25px

  &__enter
    display: flex

    &-input
      width: 460px
      height: 43px
      padding: 11px 18px
      border: none
      outline: none
      background: $inputColor
      @extend %secondText
      color: $mainColor
      &::placeholder
        color: $mainColor
        opacity: .8

    &-button
      height: 43px
      background: $btn
      outline: none
      border: none
      padding: 11px 35px
      @extend %secondText
      color: $mainColor
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      svg
        margin-right: 10px