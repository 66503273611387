$poppins: 'Poppins', sans-serif
$roboto: 'Roboto', sans-serif

%title
  font-family: $poppins
  font-style: normal
  font-weight: bold
  font-size: 48px
  line-height: 72px
  letter-spacing: -0.08em

%secondText
  font-family: $poppins
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 21px
  letter-spacing: -0.02em

%comingText
  font-family: $roboto
  font-style: normal
  font-weight: bold
  font-size: 64px
  line-height: 75px
  letter-spacing: 0.045em
