@import './variables'

// fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap')
  
*,
*:after,
*:before
  padding: 0
  margin: 0
  box-sizing: border-box

html
  background: $background

.background
  position: absolute
  left: 0
  top: 0
  z-index: -1